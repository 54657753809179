import { LogoutUser } from "../../services/authService";
import { DeleteUser } from "../../services/userService";
import { Modal, Form, Button, Alert, Spinner } from "react-bootstrap"
import { useState } from "react";
import { CardType } from "../../types/CardType";
import { UserType } from "../../types/UserType";
import { useNavigate } from "react-router-dom";
interface DeleteProfileModalProps {
    showModal: boolean;
    onClose: () => void;
    currentUser: UserType;
    cards: CardType[];
}
const DeleteProfileModal: React.FC<DeleteProfileModalProps> = ({ onClose, showModal, currentUser, cards }) => {
    var navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const deleteAccount = async () => {
        try {
            setLoading(true)
            var success = await DeleteUser(currentUser.id!, currentUser.isOauthAccount);
            if (success) {
                await LogoutUser();
                await navigate("/login?accountDeleted=true")
                window.location.reload();
            } else {
                setError(true)
                setTimeout(() => setError(false), 3000)
            }
            setLoading(false)
        } catch (error) {
            setError(true)
            setTimeout(() => setError(false), 3000)
            console.error("Error during logout or user deletion:", error);
            setLoading(false)
        }
    }
    return (<div>

        <Modal show={showModal} onHide={onClose}>
            <Modal.Header closeButton style={{backgroundColor: "#2A2D34", color: "white"}}>
                <Modal.Title >Delete your account</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{backgroundColor: "#2A2D34", color: "white"}}>
                <Form>
                    <Form.Group controlId="message">
                        {error && <Alert variant="danger">Something went wrong - please try logging out then back in and try again.</Alert>}
                        <Form.Label>This action cannot be undone. Are you sure you want to <span style={{ color: "red" }}>delete your profile</span>?</Form.Label>
                        {cards.length > 0 && <><Form.Text>The following cards and all archived cards will be deleted along with your profile, and transaction history:</Form.Text>
                            {cards.map((card, index) => {
                                return <>
                                    {index == 0 ? <hr /> : <></>}
                                    <p><b>Job Title:</b> {card.jobTitle}</p>
                                    <p><b>Bounty:</b> <span style={{ color: "red" }}>${card.jobBounty}</span></p>
                                    <hr />
                                </>
                            })}
                            <p>The following total amount will be refunded to the account the money came from.</p>
                            <small>Please allow 5 business days for the funds to be received</small>
                            <p><b>Total:</b> <span style={{ color: "red" }}>${cards.reduce((sum, card) => sum + card.jobBounty!, 0)}</span></p>
                        </>}

                    </Form.Group>
                    
                    <Button onClick={deleteAccount} style={{ margin: "1%", backgroundColor: "#009B72", border: "none" }} >{loading ? <Spinner /> : <>Yes, delete my account</>}</Button>
                    <Button onClick={onClose} style={{ margin: "1%", backgroundColor: "red", border: "none" }}>No, I&apos;ve changed my mind</Button>
                </Form>
            </Modal.Body>
        </Modal>
    </div>)
}
export default DeleteProfileModal;