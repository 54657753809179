import { styled } from '@mui/material';

const NotFoundContainer = styled("div")(`
  text-align: center;
  padding: 100px;
  color: white;
  margin-top: 10%;
`);

const NotFoundImage = styled("img")(`
  max-width: 300px;
`);

const NotFound = () => {
  return (
    <div style={{minHeight: "100vh"}}>
    <NotFoundContainer>
      <h1>404: Page Not Found</h1>
      <p>Oops! It seems like you've taken a wrong turn.</p>
      <a href="/home" className="button">Take me home</a>
    </NotFoundContainer>
    </div>
  );
};

export default NotFound;