const privacyPolicy = <>
    <h1>Privacy Policy</h1>

    <p><strong>Effective Date:</strong> 4/10/2024</p>

    <div class="section-title">1. Introduction</div>
    <p>
        Build My Idea ("we", "our", or "us") is committed to protecting and respecting your privacy.
        This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website
        <a href="https://www.build-my-idea.com"> www.build-my-idea.com</a> (the "Site").
        Please read this policy carefully to understand our views and practices regarding your personal data.
    </p>

    <div class="section-title">2. Information We Collect</div>
    <p>We collect the following types of information from you:</p>
    <ul>
        <li><strong>Personal Data:</strong> Information that can be used to identify you, such as your name, email address, phone number, etc.</li>
        <li><strong>Non-Personal Data:</strong> Information that cannot be used to identify you, such as anonymous usage data, referring/exit pages, and URL data.</li>
        <li><strong>Cookies and Tracking Technologies:</strong> We may use cookies, web beacons, and other tracking technologies to collect information about your activities on our Site.</li>
    </ul>

    <div class="section-title">3. How We Use Your Information</div>
    <p>We use the information we collect for various purposes, including:</p>
    <ul>
        <li>To provide, operate, and maintain our services</li>
        <li>To improve, personalize, and expand our services</li>
        <li>To understand and analyze how you use our services</li>
        <li>To communicate with you, either directly or through one of our partners, including for customer service, updates, and promotional purposes</li>
    </ul>

    <div class="section-title">4. Sharing Your Information</div>
    <p>We do not sell, trade, or otherwise transfer your personal data to outside parties except as described below:</p>
    <ul>
        <li><strong>Service Providers:</strong> We may share your information with third-party vendors, consultants, and other service providers who perform services on our behalf.</li>
        <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
    </ul>

    <div class="section-title">5. Security of Your Information</div>
    <p>
        We take reasonable measures to protect your personal data from unauthorized access, use, or disclosure.
        However, no data transmission over the Internet or wireless network can be guaranteed to be 100% secure.
    </p>

    <div class="section-title">6. Your Data Protection Rights</div>
    <p>Depending on your location, you may have the following rights regarding your personal data:</p>
    <ul>
        <li><strong>Access:</strong> You have the right to request copies of your personal data.</li>
        <li><strong>Correction:</strong> You have the right to request that we correct any information you believe is inaccurate.</li>
        <li><strong>Erasure:</strong> You have the right to request that we erase your personal data, under certain conditions.</li>
        <li><strong>Data Portability:</strong> You have the right to request that we transfer the data we have collected to another organization, or directly to you.</li>
    </ul>

    <div class="section-title">7. Children's Privacy</div>
    <p>
        Our services are not intended for children under the age of 13.
        We do not knowingly collect personally identifiable information from children under 13.
        If you become aware that a child has provided us with personal data, please contact us immediately.
    </p>

    <div class="section-title">8. Changes to This Privacy Policy</div>
    <p>
        We may update this Privacy Policy from time to time.
        Any changes will be posted on this page with an updated "Effective Date."
        We encourage you to review this policy periodically.
    </p>

    <div class="section-title">9. Contact Us</div>
    <p>
        If you have any questions about this Privacy Policy, please contact us at:
    </p>
    <p>
        Build My Idea<br />
        <a href="mailto:buildmyidea.contact@gmail.com">buildmyidea.contact@gmail.com</a><br />
    </p>
</>

export default privacyPolicy