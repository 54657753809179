import React, { ChangeEvent, useContext, useState } from 'react';
import { Modal, ToggleButton, Row, Button, InputGroup, ButtonGroup, Col, Spinner, ThemeProvider } from 'react-bootstrap';
import CurrentUserContext from '../../client/UserContextProvider';
import { CardType } from '../../types/CardType';
import { createBountyOrder } from "../../services/paymentsService";
import { PayPalOrderResponseType } from '../../types/PayPalOrderResponseType';
import { getCard, updateCard } from '../../services/cardService';
import { bountyMinAmount } from '../../Utils/baseUrl';
import { TextField, InputAdornment } from '@mui/material';
import textFieldTheme from '../../Utils/themes';
import { jobBountyFeeAsPercentage, jobBountySuggestionValues } from '../../Utils/valueSettings';

interface BountyModalProps {
    showModal: boolean;
    onClose: () => void;
    cardDetails: CardType;
}

const BountyModal: React.FC<BountyModalProps> = ({ showModal, onClose, cardDetails }) => {
    const context = useContext(CurrentUserContext);
const { currentUser } = context!
    const [selectedValue, setSelectedValue] = useState<number | null>(null);
    const [jobBounty, setJobBounty] = useState("");
    const [loading, setLoading] = useState(false)
    const [showError, setShowError] = useState(false)

    const handleToggleChange = (value: number) => {
        setSelectedValue(value);
        setJobBounty(value.toString());
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSelectedValue(null);
        setJobBounty(e.target.value);
    };

    const handlePayClick = async () => {
        setLoading(true)
        var response: PayPalOrderResponseType = await createBountyOrder(+jobBounty, cardDetails?.id!, currentUser?.id!)
        var card: CardType = await getCard(cardDetails.id!)
        card.tokenId = response.id
        await updateCard(card)
        for (let i = 0; i < response.links.length; i++) {
            const link = response.links[i];
            if (link.rel == "payer-action") {
                window.open(link.href, '_blank');
            }
        }
        setLoading(false)
    }
    const checkMin = (e: string) => {
        if (parseFloat(e) < bountyMinAmount) {
            setShowError(true)
        } else {
            setShowError(false)
        }
    }
    return (
        <Modal
            size="lg"
            backdrop="static"
            centered
            show={showModal}
            onHide={onClose}
        >
            <Modal.Header closeButton closeVariant="white" style={{ backgroundColor: "#2A2D34", color: "white" }}>
                <Modal.Title>
                    Increase Bounty
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#2A2D34", color: "white" }}>
                <h6>How much would you like to add to this cards bounty?</h6>
                <Row>
                    <Col md={6}>
                        <ButtonGroup className='mb-3'>
                            {jobBountySuggestionValues.map(value => (
                                <ToggleButton
                                    key={value}
                                    type="radio"
                                    checked={selectedValue === value}
                                    value={value}
                                    onClick={() => handleToggleChange(value)}
                                    variant="secondary" id={''}                            >
                                    ${value}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <InputGroup className="mb-3">
                            <ThemeProvider theme={textFieldTheme}>
                                <TextField
                                    id="outlined-number"
                                    error={showError}
                                    helperText={showError ? `Amount must be a minimum of $${bountyMinAmount}` : ""}
                                    label="Amount"
                                    variant="outlined"
                                    value={(jobBounty == "0" ? "" : jobBounty)}
                                    onChange={(e) => {
                                        checkMin(e.target.value)
                                        handleInputChange(e)}}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start" style={{ color: 'white' }}>$</InputAdornment>,
                                        style: { color: 'white' },
                                        inputProps: {
                                            style: {
                                                'appearance': 'textfield',
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'white' },
                                    }}
                                    sx={{
                                        my: 2,
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'white',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'white',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'white',
                                            },
                                        },
                                        '& .MuiFormHelperText-root': {
                                            color: 'white',
                                        },
                                        '& input[type=number]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0,
                                        },
                                        '& input[type=number]::-webkit-inner-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0,
                                        },
                                    }}
                                />
                            </ThemeProvider>
                        </InputGroup>
                    </Col>
                </Row>
                <p>A service fee amount of <span style={{ color: "#6761A8" }}>${+jobBounty * jobBountyFeeAsPercentage}</span> will be applied to your payment.</p>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: "#2A2D34" }}>
                <Button disabled={showError} onClick={handlePayClick} style={{ backgroundColor: "green", border: "none" }}>{loading ? <Spinner /> : <>Pay Now</>}</Button>
                <Button onClick={onClose} style={{ backgroundColor: "red", border: "none" }}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BountyModal;