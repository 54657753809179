import React from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import { UserType } from '../../types/UserType';
import { Divider } from '@mui/material';
import DefaultProfilePicture from '../../images/default-profile-pic.png'
import { useNavigate } from 'react-router-dom';
interface UserCardProps {
  user: UserType | null;
  onActivate: () => void;
  onDeactivate: () => void;
  onSetAdmin: () => void;
}

const UserCard: React.FC<UserCardProps> = ({ user, onActivate, onDeactivate, onSetAdmin}) => {
  const navigate = useNavigate();
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Img
        variant="top"
        src={user?.profilePicture ? "data:image/png;base64," + user?.profilePicture : DefaultProfilePicture}
        alt="User Avatar"
        className="rounded-circle mt-3"
        style={{ width: '100px', height: '100px', margin: '0 auto' }}
      />
      <Card.Body>
        <Card.Title className='text-center'>Selected User:</Card.Title>
        <Card.Subtitle className="mb-2 text-muted text-center">{user?.firstName} {user?.lastName}</Card.Subtitle>
        <Divider className='my-2'/>
        <Card.Subtitle>Email:</Card.Subtitle>
        <Card.Text>{user?.email}</Card.Text>
        <Card.Subtitle>Last Online:</Card.Subtitle>
        <Card.Text>{user?.lastOnline}</Card.Text>
        <Card.Subtitle>Payment Email:</Card.Subtitle>
        <Card.Text>{user?.paymentEmail ? user?.paymentEmail : "N/A"}</Card.Text>
        <Card.Subtitle className="mt-2">Quick Actions</Card.Subtitle>
        <div className="btn-group mt-2" role="group" aria-label="Quick Actions">
          <Button variant={user?.isActivated ? "danger" : "secondary"} onClick={onDeactivate} disabled={!user?.isActivated}>
            Deactivate User
          </Button>
          <Button variant={user?.isActivated ? "secondary" : "success"} onClick={onActivate} disabled={user?.isActivated}>
            Activate User
          </Button>
        </div>
        <div className="mt-2 mx-2 btn-group" role="group">
          <Button className='mt-2' variant='outline-primary' onClick={onSetAdmin}>{user?.userRole == 0 ? 'Make Admin' : 'Revoke Admin'}</Button>
          <Button className='mt-2' variant='outline-secondary' onClick={() => {navigate(`/profile/${user?.id}`)}}>View Profile</Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default UserCard;