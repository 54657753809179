import CurrentUserContext from '../client/UserContextProvider';
import DeleteProfileModal from '../components/settings/DeleteProfileModal';
import Styles from '../css/settings/settings.module.css'
import Logout from '@mui/icons-material/Logout';
import { UpdateUserInfo } from '../services/userService';
import { UserType } from '../types/UserType';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Button, Alert, Spinner, Form } from 'react-bootstrap'
import { LogoutUser, ResetUserPassword } from '../services/authService';
import { getAllCardsForUser } from '../services/cardService';
import { CardType } from '../types/CardType';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider } from '@mui/material';
import { StyledTextField, Theme } from '../components/shared/styledTextField';
import { getUserPayments } from '../services/paymentsService';
import { Payment } from '../types/PaymentType';
import { format } from 'date-fns';
import { UserUpdateSuccessResponseType } from '../types/UserUpdateSuccessResponseType';
const SettingsPage = () => {
  const context = useContext(CurrentUserContext);
const { currentUser } = context!
  const [firstName, setFirstName] = useState(currentUser?.firstName)
  const [lastName, setLastName] = useState(currentUser?.lastName)
  const [username, setUsername] = useState(currentUser?.username)
  const [paymentEmail, setPaymentEmail] = useState(currentUser?.paymentEmail)

  const [editFirstName, setEditFirstName] = useState(false)
  const [editLastName, setEditLastName] = useState(false)
  const [editUsername, setEditUsername] = useState(false)
  const [editPaymentEmail, setEditPaymentEmail] = useState(false)

  const [cards, setCards] = useState<CardType[]>([])

  const [errorMessage, setErrorMessage] = useState("Something went wrong - please try again later")
  const [showDeleteProfileModal, setShowDeleteProfileModal] = useState(false)
  const [resetPasswordAlert, setResetPasswordAlert] = useState(false)
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false)

  const [fieldUpdated, setFieldUpdated] = useState(false)
  const [isError, setIsError] = useState(false)
  const [transactions, setTransactions] = useState<Payment[]>([])
  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser.firstName)
      setLastName(currentUser.lastName)
      setUsername(currentUser.username)
      setPaymentEmail(currentUser.paymentEmail)
      const fetchCards = async () => {
        setCards(await getAllCardsForUser(currentUser.id!))
        setTransactions(await getUserPayments(currentUser.id!))
      }
      fetchCards()
    }
  }, [currentUser])

  const renderValues = (value: string, updateFunc: Dispatch<SetStateAction<string | undefined>>,
    editValue: boolean, updateEditBool: Dispatch<SetStateAction<boolean>>, relevantField: string, maxLength: number = 10,
    minLength: number = 2, name: string, fallbackValue: string,  type: string = "input") => {
    return <>{!editValue && <span className={Styles.userData}>{value}</span>}
      {editValue &&
        <ThemeProvider theme={Theme}>
          <Form onSubmit={async (e) => {
            e.preventDefault()
              var success = await handleSave(value, relevantField)
              if (!success) {
                updateFunc(fallbackValue)
              }
              updateEditBool(!editValue)
          }}>
            <StyledTextField className={Styles.settingsUpdateInput} type={type} label={name} variant="standard" inputProps={{ maxLength: maxLength, minLength: minLength }} value={value} onChange={(e) => updateFunc(e.target.value)} />
            {editValue && <Button className={Styles.editButton} type="submit" disabled={value != null && value.length < minLength}>Save</Button>}
          </Form>
        </ThemeProvider>}
      {!editValue && <Button className={Styles.editButton} onClick={() => { updateEditBool(!editValue) }}>Edit</Button>}
    </>
  }

  const LogoutUserCheck = async () => {
    await LogoutUser();
    window.location.href = "/login";
  };

  const handleSave = async (value: any, name: string) => {
    if (currentUser && name in currentUser) {
      if (currentUser[name as keyof UserType] !== value) {
        (currentUser as any)[name] = value;
        try {
          var response: UserUpdateSuccessResponseType = await UpdateUserInfo(currentUser);
          if (response.success) {
            updatedFieldAlert()
            return true;
          }
          setErrorMessage(response.message!)
          setIsError(true)
          setTimeout(() => { setIsError(false) }, 5000)
          return false;
        }
        catch (error) {
          setIsError(true)
          setTimeout(() => { setIsError(false) }, 5000)
          return false;
        }
      }
    }
  }
  const onDeleteProfileModalClose = () => {
    setShowDeleteProfileModal(false)
  }

  const updatedFieldAlert = () => {
    setFieldUpdated(true)
    setTimeout(() => { setFieldUpdated(false) }, 5000)
  }
  const formatDate = (date: string) => {
    var formatedDate = format(new Date(date), "dd/MM/yyyy")
    return formatedDate
  }
  return (
    <div id={Styles.settingsWrapper}>

      {resetPasswordAlert && <Alert variant="success" className={Styles.alert}>Password reset link has been sent to your email</Alert>}
      {fieldUpdated && <Alert variant="success" className={Styles.alert}>Field Updated</Alert>}
      {isError && <Alert variant="danger" className={Styles.alert}>{errorMessage}</Alert>}
      <div id={Styles.updateValuesWrapper}>
        <p className={Styles.settingsText}>Manage Account</p>
        <hr style={{ borderColor: "#1E1E1E" }} />
        <p className={Styles.settingsText}>Email: <span className={Styles.userData}>{currentUser?.email}</span></p>
        <p className={Styles.settingsText}>First Name: {renderValues(firstName!, setFirstName, editFirstName, setEditFirstName, "firstName", 10, 2, "First Name", currentUser?.firstName!)}</p>
        <p className={Styles.settingsText}>Last Name: {renderValues(lastName!, setLastName, editLastName, setEditLastName, "lastName", 10, 2, "Last Name", currentUser?.lastName!)}</p>
        <p className={Styles.settingsText}>Username: {renderValues(username!, setUsername, editUsername, setEditUsername, "username", 10, 2, "Username", currentUser?.username!)}</p>
        <p className={Styles.settingsText}>PayPal Payment Email: {renderValues(paymentEmail!, setPaymentEmail, editPaymentEmail, setEditPaymentEmail, "paymentEmail", 45, 2, "Payment Email", "email")}</p>

        <div id={Styles.passwordButtonWrapper}>
          <Button id={Styles.changePasswordButton} onClick={async () => {
            setResetPasswordLoading(true)
            var success = await ResetUserPassword(currentUser?.email!)
            if (success) {
              setResetPasswordAlert(true)
              setTimeout(() => setResetPasswordAlert(false), 3000)
            }
            setResetPasswordLoading(false)
          }}>{resetPasswordLoading ? <Spinner /> : <>Change Password</>}</Button>

        </div>
        <Button id={Styles.logoutButton} onClick={LogoutUserCheck}><Logout /> Logout</Button>
        <hr style={{ borderColor: "#1E1E1E" }} />
        <p className={Styles.settingsText}>Transaction History</p>
        <hr style={{ borderColor: "#1E1E1E" }} />
        <Paper id={Styles.transactionHistoryTable}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    key="1"
                    align="center"
                    style={{ minWidth: "170", color: "white", backgroundColor: "#464B566E" }}
                  >
                    Id
                  </TableCell>
                  <TableCell
                    key="1"
                    align="center"
                    style={{ minWidth: "170", color: "white", backgroundColor: "#464B566E" }}
                  >
                    Purchase Date
                  </TableCell>
                  <TableCell
                    key="1"
                    align="center"
                    style={{ minWidth: "170", color: "white", backgroundColor: "#464B566E" }}
                  >
                    Purchase Amount
                  </TableCell>
                  <TableCell
                    key="1"
                    align="center"
                    style={{ minWidth: "170", color: "white", backgroundColor: "#464B566E" }}
                  >
                    Service Fee
                  </TableCell>
                  <TableCell
                    key="1"
                    align="center"
                    style={{ minWidth: "170", color: "white", backgroundColor: "#464B566E" }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions
                  .map((transaction, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index} >
                        <TableCell key={index} align="center" style={{ color: "white" }}>
                          {transaction.id}
                        </TableCell>
                        <TableCell key={index} align="center" style={{ color: "white" }}>
                          {formatDate(transaction.purchaseDate!)}
                        </TableCell>
                        <TableCell key={index} align="center" style={{ color: "white" }}>
                          ${(transaction.bountyPrice).toFixed(2)}
                        </TableCell>
                        <TableCell key={index} align="center" style={{ color: "white" }}>
                          ${(transaction.fee).toFixed(2)}
                        </TableCell>
                        <TableCell key={index} align="center" style={{ color: "white" }}>
                          {transaction.status}
                        </TableCell>

                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

        </Paper>
        <div id={Styles.dangerZone}>
          <p className={Styles.settingsText} style={{ color: "red" }}>Danger Zone</p>
          <hr style={{ borderColor: "red" }} />
          <Button id={Styles.deleteAccountButton} onClick={() => setShowDeleteProfileModal(true)}>Delete Account</Button>

          <DeleteProfileModal onClose={onDeleteProfileModalClose} showModal={showDeleteProfileModal} currentUser={currentUser!} cards={cards} />
        </div>
      </div>
    </div >
  )
}
export default SettingsPage