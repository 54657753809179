import { Spinner } from "react-bootstrap";
import Style from "../../css/shared/customLoadingSpinner.module.css"
const CustomLoadingSpinner = () => {
    return (
        <div id={Style.spinnerWrapper}>
            <Spinner id={Style.spinner}/>
        </div>
    )
}

export default CustomLoadingSpinner;